.header-menu.m{
    font-size: 14px;
    color: black;
    font-weight: 700;
}
.header-menu:hover{
    color: #949494;
}
.header-button{
    background-color: #D565f5;
    padding: 60px 30px;
    height: 55px;
    border: 1px solid white;
    font-weight: 800;
    font-size: 16px;
    color: #ffffff;
}
.header-button:hover{
    background-color: #ffffff;
    color:#D565f5 ;
    border: 1px solid #D565f5;
}
@media (max-width: 768px) {
    .header-menu {
        font-size: 12px;
        color: #000;
        font-weight: 700;
    }
    .overflow-hidden {
        background-color: #fff;
        border-radius: 10px;
        padding-left: 10px;
        padding-right: 10px;
        z-index: 1;
    }
    .header-button {
        background-color: #D565f5;
        padding: 60px 30px;
        height: 39px;
        border: 1px solid white;
        font-weight: 800;
        font-size: 13px;
        color: #ffffff;
        margin-left: 7px;
    }
    .fixed-top {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
    }
}

line {
    color: #fff;
}
.navbar-blur {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
  }
.char {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;

    font-size: 15px;
    font-family: "Raleway", arial, sans-serif !important;
    color: #444;
    line-height: 190%;
}

ul li {list-style-type: disc;}

.store-imgs{
    display: flex;
    height: 45px;
}

.nameofgame {
    font-size: 33px;
    font-family: "Raleway", arial, sans-serif !important;
    color: #444;
font-weight: 800;
}
.detailofgame {
    display: flex;
    justify-content: space-between;
}