.footer-bg{
    background-color: black;
}

.f-30{
    font-size: 26px;
    line-height: 37px;
    color: white;
}
.f-15 {
    font-size: 16px !important;
    color: white;
    font-weight: 800;
}
* {
    font-family: "Montserrat", Sans-serif;
}
.ml-10{
    margin-left: 20px;
}
p a{
    font-weight: 500;
}
.pb-70{
    padding-bottom: 70px;
}
@media (max-width: 767px) {
    .grid.grid-cols-4.md\:grid-cols-4.lg\:grid-cols-4.gap-6.mb-10.mt-4.pb-70 {
        display: flow !important;
    }
    .ml-10 {
        margin-left: 0 !important;
    }
    .pb-70 {
        padding-bottom: 0px !important;
    }
}
.inline-flex > .fa-brands 
{
    border-color: #FFFFFF;
    border: 2px solid;
    padding: 8px;
border-radius: 4px;
}
a {
    text-decoration: none !important;
}