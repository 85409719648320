.contactus {
    background-image: url("../../assets/image6.jpg");
    background-position: center center;
    background-size: cover;
}
.mx-p {
    max-width: min(100%, 1140px);
    margin-left: auto;
    margin-right: auto;
}
.form-label {
    font-size: 1.1em;
    color: #fff;
    font-weight: 700;
}
input , textarea{
    background-color: transparent !important;
    border: 1px solid #fff;
}
.form-control {
    color: #fff !important;
    padding: 12px;
    font-size: 14px;
}
.btn-secondary {
    padding: 12px 32px;
    margin-right: 5px;
    background-color: #3D3D3D;
    color: white;
    vertical-align: top;
    font-size: 0.8125em;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 0;
    border: none;
}