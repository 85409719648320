/* Slider3D.css */
.slider-container {
    width: 90%;
    margin: auto;
    perspective: 1000px;
  }
  
  .slick-slide {
    transition: transform 0.5s ease-in-out;
  }
  
  .slick-center .slider-item {
    transform: scale(1) rotateY(0deg);
    transition: transform 0.5s ease;
  }
  
  .slick-prev,
  .slick-next {
    z-index: 1;
  }
  
  .slider-item {
    padding: 10px;
    transform: scale(0.8) rotateY(15deg);
    transition: transform 0.5s ease;
  }
  
  .slider-item img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  img.img-fluid.banner-img {
    height: 682px;
    width: 916PX;
}


.mx-multi {
  mix-blend-mode: multiply !important;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.imgs-h {
  z-index: 8;
  font-family: Poppins;
  touch-action: pan-y;
  height: auto;
  width: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  vertical-align: top;
  text-align: right;
  line-height: 80px;
  letter-spacing: -1px;
  font-weight: 800;
  font-size: 90px;
  padding: 50px 30px 30px;
  backdrop-filter: none;
  filter: none;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: visible;
}
.imgs-c {
  z-index: 7;
  font-family: Poppins;
  touch-action: pan-y;
  height: auto;
  width: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  vertical-align: top;
  text-align: right;
  line-height: 28px;
  letter-spacing: 0px;
  font-weight: 300;
  font-size: 20px;
  padding-left: 30px;
  padding-right: 30px;
  backdrop-filter: none;
  filter: none;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: visible;
}
.imgs-txt{
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  width: 628px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-27%, 28%);
  max-width: 100%;
}
.imgs > img {
  height: 800px;
  width: 930px;
}



.imgss-h {
  z-index: 8;
  font-family: Poppins;
  touch-action: pan-y;
  height: auto;
  width: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  vertical-align: top;
  text-align: center;
  line-height: 80px;
  letter-spacing: -1px;
  font-weight: 800;
  font-size: 90px;
  padding: 50px 30px 30px;
  backdrop-filter: none;
  filter: none;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: visible;
}
.imgss-c {
  z-index: 7;
  font-family: Poppins;
  touch-action: pan-y;
  height: auto;
  width: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  vertical-align: top;
  text-align: center;
  line-height: 28px;
  letter-spacing: 0px;
  font-weight: 300;
  font-size: 20px;
  padding-left: 30px;
  padding-right: 30px;
  backdrop-filter: none;
  filter: none;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: visible;
}
.imgss-txt{
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  width: 628px;
  max-width: 100%;
  z-index: 1;
}
.imgss > img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  width: 1240px;
  height: 540px;
  z-index: -1;
}



.imgsss-h {
  z-index: 8;
  font-family: Poppins;
  touch-action: pan-y;
  height: auto;
  width: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  vertical-align: top;
  text-align: left;
  line-height: 80px;
  letter-spacing: -1px;
  font-weight: 800;
  font-size: 90px;
  padding: 50px 30px 30px;
  backdrop-filter: none;
  filter: none;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: visible;
}
.imgsss-c {
  z-index: 7;
  font-family: Poppins;
  touch-action: pan-y;
  height: auto;
  width: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  vertical-align: top;
  text-align: left;
  line-height: 28px;
  letter-spacing: 0px;
  font-weight: 300;
  font-size: 20px;
  padding-left: 30px;
  padding-right: 30px;
  backdrop-filter: none;
  filter: none;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: visible;
}
.imgsss-txt{
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  width: 682px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-74%, -125%);
  max-width: 100%;
}
.imgsss > img {
  height: 800px;
  width: 930px;
  margin-left: auto;
}

.l-none.sm-block {
  display: none;
}

.l-block.sm-none {
  display: block;
}


@media (max-width: 768px) {


  .imgss.nw {
    height: 295px;
}

  p.imgss-c {
    z-index: 8;
    font-family: Poppins;
    touch-action: pan-y;
    height: auto;
    width: auto;
    color: rgb(0, 0, 0);
    text-decoration: none;
    white-space: normal;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    vertical-align: top;
    text-align: left;
    line-height: 14px;
    letter-spacing: 0px;
    font-weight: 300;
    font-size: 10px;
    padding-left: 16px;
    padding-right: 16px;
    backdrop-filter: none;
    filter: none;
    transform-origin: 50% 50%;
    opacity: 1;
    transform: translate(0px, 0px);
    visibility: visible;
  }


  .col-12 >.imgss > img {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    width: auto;
    height: 100%;
    z-index: -1;
  }

  p.imgss-h {
    z-index: 9;
    font-family: Poppins;
    touch-action: pan-y;
    height: auto;
    width: auto;
    color: rgb(0, 0, 0);
    text-decoration: none;
    white-space: normal;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    vertical-align: top;
    text-align: center;
    line-height: 27px;
    letter-spacing: -1px;
    font-weight: 800;
    font-size: 31px;
    padding: 16px;
    backdrop-filter: none;
    filter: none;
    transform-origin: 50% 50%;
    opacity: 1;
    transform: translate(0px, 0px);
    visibility: visible;
  }
  p.imgss-c {
    font-size: 10px;
    z-index: 8;
    font-family: Poppins;
    touch-action: pan-y;
    height: auto;
    width: auto;
    color: rgb(0, 0, 0);
    text-decoration: none;
    white-space: normal;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    vertical-align: top;
    text-align: center;
    line-height: 14px;
    letter-spacing: 0px;
    font-weight: 300;
    font-size: 10px;
    padding-left: 16px;
    padding-right: 16px;
    backdrop-filter: none;
    filter: none;
    transform-origin: 50% 50%;
    opacity: 1;
    transform: translate(0px, 0px);
    visibility: visible;
  }



  .l-none.sm-block {
    display: block;
  }

  .l-block.sm-none {
    display: none;
  }

  
}
