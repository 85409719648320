
.comingsoon {
    background-image: url("../../assets/section5_bg.jpg");
    background-position: center center;
    background-size: cover;
}
.char {
    width: 100%; 
    max-width: 1140px; 
    margin: 0 auto; 
  }
.character-pics {
    height: 160px;
    width: 181px;
    border-radius: 25px;
}

.carousel-container {
    perspective: 1000px;
    width: 800px;
    margin: auto;
  }
  
  .carousel-3d .carousel-item {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 800px;
    height: 400px;
    transform-origin: center;
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-3d .active {
    z-index: 1;
  }
  .min-h-72 {
    min-height: 100vh;

  }
  .min-h-72 img {
    height: 800px !important;
  }

  @media (max-width: 767px) {
    .char .col-sm-12 {
      flex: 0 0 50%;   /* Each image takes 50% of the row width */
      max-width: 50%;  /* Restricts the max width to 50% */
  }

  .char .row {
      gap: 0px;       /* Adds some spacing between the images */
  }
  .character-pics {
    height: 140px;
    width: 160px;
    border-radius: 25px;
}
.widget-container.se > .img-fluid {
  width: 100% !important;
}
}