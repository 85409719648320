.hero {
    background-image: url('../../../public/assets/section1_bg.webp');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    -webkit-mask-image: linear-gradient(black 90%, transparent);
    mask-image: linear-gradient(black 90%, transparent);
}
.sticky{
    position: sticky;
    top: 0;
}
.sign-board {
    height: 350px;
    position: absolute;
    bottom: 0;
}
.icon-bar {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  
  .icon-bar a {
    display: block;
    text-align: center;
    padding: 10px 12px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
    border-radius: 9px;
    margin-top: 10px;
  }
  
  .facebook {
    background: #3B5998;
    color: white;
  }
  
  .twitter {
    background: #55ACEE;
    color: white;
  }
  
  .youtube {
    background: #bb0000;
    color: white;
  }