.character {
    background-image: url("../../assets/section5_bg.jpg");
    background-position: center center;
    background-size: cover;
}

.carousel-indicators button.thumbnail {
    width: 100px;
  }
  .carousel-indicators button.thumbnail:not(.active) {
    opacity: 0.7;
  }
  .carousel-indicators {
    position: static;
  }
  @media screen and (min-width: 992px) {
    .carousel {
      max-width: 100%;
      margin: 0 auto;
    }
  }
  .carousel-indicators button.thumbnail {
    width: 210px;
    height: auto;
}
.h-126 {
    height: 126px;
}
.carousel-indicators{
    gap: 50px;
}
.d-block.img-fluid {
    height: 500px;
    margin-left: auto;
    margin-right: auto;
}
.char-name {
    font-family: Raleway;
    color: rgb(255, 255, 255);
    text-align: center;
    line-height: 14px;
    letter-spacing: -1px;
    font-weight: 900;
    font-size: 60px;
    backdrop-filter: none;
    visibility: visible;
}
@media (max-width: 768px) {
  .d-block.img-fluid {
    height: 330px;
    margin-left: auto;
    margin-right: auto;
}
.carousel-indicators {
  margin-left: 0;
  margin-right: 0
}
.carousel {
  padding: 0;
}
.carousel-indicators {
  gap: 0;
}
}
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');